import { useState, useEffect } from 'react';
import { Tooltip as RTooltip, Container, Row, Col, Spinner, Form, Modal, Button, ButtonGroup, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import dateFormat from 'dateformat';
import { now } from 'moment/moment.js';

export const ShareQuantityUpdate = ({showData, handleCancel}) => {
    const [shareQuantityChange, setShareQuantityChange] = useState({
      id: 0,
      dt: new Date(),
      diff: 0
    });
    const [buttonType, setButtonType] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  
    const recordShareChange = async (e) => {
      setSaving(true);
      setErrorMessage("");
  
      var shareToUpdate = shareQuantityChange;
      //make it a negative number if we are selling the shares
      if(buttonType === true) {
        shareToUpdate.diff = parseFloat(shareQuantityChange.diff) * -1.0;
        console.log(shareToUpdate);
      }
  
      const valueResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Share/Quantity', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
          'Origin': 'https://localhost:44386'        
        },
        body: JSON.stringify(shareToUpdate),
      })
      .then((response) => {
        if(response.status == 200) {
          handleCancel(true);
          setSaving(false);
        } else {
          setErrorMessage("Share quantity could not be updated.");
          setSaving(false);
        }    
      })    
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err);
        setSaving(false);
      });    
      
      setSaving(false);
    }
  
    useEffect(()=> {
      setShareQuantityChange({...shareQuantityChange,
        dt: dateFormat(now(), "yyyy-mm-dd"),
        id: showData.dbId
      });
    }, [showData]);

    return (
      <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-sharevolume" >
        <Modal.Header closeButton>
          <Modal.Title id="modal-sharevolume">Share change for {showData.shareName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="sharevolume.Date">
              <Form.Label>Date of Change:</Form.Label>
              <Form.Control type="date" 
                value={shareQuantityChange.dt} 
                onChange={(e) => setShareQuantityChange({...shareQuantityChange, 
                  dt: dateFormat(e.target.valueAsDate, "yyyy-mm-dd")})}/>
            </Form.Group>
            <Form.Group>
              <ButtonGroup>
                <Button variant={(buttonType == false)? "primary": "outline-primary"} onClick={() => setButtonType(false)}>Buy</Button>
                <Button variant={(buttonType == true)? "primary": "outline-primary"} 
                    onClick={() => { 
                      setButtonType(true); 
                      setShareQuantityChange({...shareQuantityChange, diff: 0})}}> 
                  Sell
                </Button>
              </ButtonGroup>
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="sharevolume.count">
              <Form.Label>Number of shares:</Form.Label>
              <Form.Control type="number" value={shareQuantityChange.diff} onChange={(e) => setShareQuantityChange({...shareQuantityChange, 
                diff: (parseFloat(e.target.value) > showData.maxShares && buttonType == true)? showData.maxShares: parseFloat(e.target.value)})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="sharevolume.aftersale">
              <Form.Label>Shares after transaction:</Form.Label>
              <Form.Control type="number" value={parseInt(showData.maxShares) + ((buttonType == false)? parseInt(shareQuantityChange.diff): parseInt(shareQuantityChange.diff)*-1)} readOnly disabled/>              
            </Form.Group>
          </Form>
        </Modal.Body>
        {saving == false && (
          <Modal.Footer>
              <Button variant="primary" onClick={recordShareChange}>Record</Button>
              <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        )}
        {errorMessage != "" && (
          <Alert key='warning' variant='warning'>
            {errorMessage}
          </Alert>
        )}
        {saving == true && (
          <Modal.Footer>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Saving...</span>
            </Spinner>
          </Modal.Footer>
        )}
      </Modal>  
    )
  }