import { useState, useEffect } from 'react';
import { Tooltip as RTooltip, Container, Row, Col, Spinner, Form, Modal, Button, ButtonGroup, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import dateFormat from 'dateformat';

export const ShareUpdate = ({showData, handleCancel}) => {
  const [shareChange, setShareChange] = useState({
    name: "",
    id: 0,
    ah: 0,
    ep: "",
    url: "",
    c: "#ff0000",
    sym: ""
  });
  const [buttonType, setButtonType] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const recordShareUpdate = async (e) => {
    setSaving(true);
    setErrorMessage("");

    const valueResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Share/Update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(shareChange),
    })
    .then((response) => {
      if(response.status == 200) {
        handleCancel(true);
        setSaving(false);
      } else {
        setErrorMessage("Share could not be updated.");
        setSaving(false);
      }    
    })    
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
      setErrorMessage(err);
      setSaving(false);
    });    
    
    setSaving(false);
  }

  useEffect(()=> {
    setShareChange({...shareChange,
      name: showData.name,
      ep: showData.elementPath,
      url: showData.url,
      ah: showData.amountHeld,
      c: showData.displayColour.trim(),
      id: showData.dbId,
      sym: showData.symbol
    });
  }, [showData]);

  return (
    <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-sharevolume" >
      <Modal.Header closeButton>
        <Modal.Title id="modal-sharevolume">Share Update for {showData.shareName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="share.name">
            <Form.Label>Share Name:</Form.Label>
            <Form.Control type="text" value={shareChange.name} onChange={(e) => setShareChange({...shareChange, 
              name: e.target.value})} />
          </Form.Group>          
          <Form.Group className="mb-3" controlId="share.url">
            <Form.Label>URL:</Form.Label>
            <Form.Control type="text" value={shareChange.url} onChange={(e) => setShareChange({...shareChange, 
              url: e.target.value})} />            
          </Form.Group>          
          <Form.Group className="mb-3" controlId="share.elementPath">
            <Form.Label>HTML Path:</Form.Label>
            <Form.Control type="text" value={shareChange.ep} onChange={(e) => setShareChange({...shareChange, 
              ep: e.target.value})} />            
          </Form.Group>
          <Form.Group className="mb-3" controlId="share.symbol">
            <Form.Label>Symbol (ticker):</Form.Label>
            <Form.Control type="text" value={shareChange.sym} onChange={(e) => setShareChange({...shareChange, 
              sym: e.target.value})} />            
          </Form.Group>          
          <Form.Group className="mb-3" controlId="share.colour">
            <Form.Label>Colour:</Form.Label>
            <Form.Control type="color" value={shareChange.c} onChange={(e) => 
              setShareChange({...shareChange, 
                c: e.target.value})} 
            />            
          </Form.Group>

          <Form.Group className="mb-3" controlId="share.count">
            <Form.Label>Number of start:</Form.Label>
            <Form.Control type="number" value={shareChange.ah} onChange={(e) => setShareChange({...shareChange, 
              ah: e.target.value})} />
          </Form.Group>
        </Form>
      </Modal.Body>
      {saving == false && (
        <Modal.Footer>
            <Button variant="primary" onClick={recordShareUpdate}>Record</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      )}
      {errorMessage != "" && (
        <Alert key='warning' variant='warning'>
          {errorMessage}
        </Alert>
      )}
      {saving == true && (
        <Modal.Footer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        </Modal.Footer>
      )}
    </Modal>  
  )
}