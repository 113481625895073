import {React, useEffect, useRef } from "react";
import { useTable, usePagination} from "react-table";
import { Pagination, Form } from 'react-bootstrap';

export default function Table({ columns, data, defaultPage, pageNotifier, pageTrigger }) {
    const pageSelection = useRef(null);
    // Use the useTable Hook to send the columns and data to build the table
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      page, // rows for the table based on the data passed
      nextPage,
      previousPage,
      canPreviousPage,
      canNextPage,
      gotoPage,
      pageOptions,
      state,
      prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
      } = useTable({
          columns,
          data,
          initialState: {pageSize: 20}
        },
        usePagination,
        
      );
      
    const {pageIndex} = state;
    /* 
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    const customGotoPage = (index) => {
      //check some constraints
      if(index >= 0 && index < pageOptions.length) {

        gotoPage(index);

        if(pageSelection != null && pageSelection.current != null && index != null) {
          pageSelection.current.value = parseInt(index);
        }
        

        if(pageNotifier != null) {
          pageNotifier(index);
        }          
      }
    }

    const PageSelection = () => {
      var map = [];

      for(var i = 0; i < pageOptions.length; i++) {
        map.push(i);
      }

      return map.map((x) => {
        return (
          <option key={x} value={x}>{x+1}</option>
        )
      });
    }

    useEffect(()=> {
      if(defaultPage != null) {
        customGotoPage(defaultPage);
      }
    }, [defaultPage, pageTrigger]) 

    return (
      <>
        <table {...getTableProps()} className="tableContainer"  >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => ( 
                  <th {...column.getHeaderProps()} className={column.className} key={j}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="tableContainer" >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return <td key={j} {...cell.getCellProps()} className={cell.column.className}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          {pageOptions.length > 1 && (
            <>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
              <Pagination>
                {pageIndex == 0 && (
                  <Pagination.First active />
                )}
                {pageIndex != 0 && (
                  <Pagination.First onClick={() => {customGotoPage(0)}} />
                )}
                <Pagination.Prev onClick={() => {customGotoPage(pageIndex - 1)}} />
                <Pagination.Next onClick={() => {customGotoPage(pageIndex + 1)}} />
                {pageIndex == (pageOptions.length-1) && (
                  <Pagination.Last active />
                )}
                {pageIndex != (pageOptions.length-1) && (
                  <Pagination.Last onClick={() => {customGotoPage(pageOptions.length-1)}} />
                )}
                <Form.Select 
                  ref={pageSelection} 
                  onChange={(e) => customGotoPage(parseInt(e.target.value))}
                >
                  {PageSelection()}
                </Form.Select>
              </Pagination>
            </>
          )}
        </div>
      </>
    );
  }