import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Container, Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";

export default function Root() {
  const navigate = useNavigate();

  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
     
    return tempToken;
  }

  const readUser = () => {
    var tempUser = sessionStorage.getItem("loggedUser");
     
    return tempUser;
  }


  return (
    <>
      <div>
        {readToken() == null && (
          <Navbar collapseOnSelect fixed="top" expand="sm" bg="dark" data-bs-theme="dark">
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-login" />
              <Navbar.Brand href="login">Login</Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-login">
                <Nav className="me-auto">
                  <Nav.Link href="/login">Login</Nav.Link>
                  <Nav.Link href="/forgotpassword">Forgot Password</Nav.Link>
                  <Nav.Link href="/registeruser">Register User</Nav.Link>
                </Nav>          
              </Navbar.Collapse>
            </Container>        
          </Navbar>
        )}
        {readToken() != null && (
          <Navbar collapseOnSelect fixed="top" expand="sm" bg="primary" data-bs-theme="dark" >
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Brand href="login">Home</Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/shareinformation">Shares</Nav.Link>
                  <Nav.Link href="/pensioninformation">Pension</Nav.Link>
                  <Nav.Link href="/wealthinformation">Wealth</Nav.Link>
                  <Nav.Link href="/growthinformation">Growth</Nav.Link>                
                  <Nav.Link href="/bankinginformation">Banking</Nav.Link>                
                  <Nav.Link href="/logout">Log out</Nav.Link>
                </Nav>          
                <Navbar.Text>
                  Signed in as: {readUser()}
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>        
          </Navbar>
        )}   
      </div>
      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}