
import { useState, useEffect } from 'react';
import { Spinner, Form, Modal, Button, InputGroup, Col, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import dateFormat from 'dateformat';
import {useFormik} from 'formik';
import * as yup from 'yup';

export const PensionValue = ({showData, handleCancel}) => { 
  const [saving, setSaving] = useState(false);

  const recordPensionValue = async (dataToRecord) => {
    setSaving(true);

    const valueResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Pension/Value', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(dataToRecord),
    })
    .then((response) => {
      handleCancel(true);  
      return true;
    })    
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
      setSaving(false);
    });    
    
    setSaving(false);
  }

  const handleSubmit= (values) => {
  
    var newDatatype = {
        id: showData.dbId,
        date: dateFormat(values.date, 'yyyy-mm-dd'),
        val: values.value
    }

    recordPensionValue(newDatatype);    
  }

  useEffect(()=> {
    formik.initialValues.date = dateFormat(showData.ValueDate, 'yyyy-mm-dd');
    formik.initialValues.value = showData.Value;
    formik.resetForm();
  }, [showData]);

  const schema = yup.object().shape({
    date: yup.string()
        .required("Required"),
    value: yup.string()
        .matches(/^\d+(?:\.\d{0,2})?$/, "Must only contain numeric characters")
        .required("Required"),    
  });

  const formik = useFormik({
    onSubmit: (values) => handleSubmit(values),
    validationSchema: schema,
    initialValues:{
      date: dateFormat(showData.ValueDate, 'yyyy-mm-dd'),
      value: showData.Value
    },
    
  });

  return (

    <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-value" >
      <Modal.Header closeButton>
        {showData.dbId == 0 && (
          <Modal.Title id="modal-value">Add Valuation</Modal.Title>
        )}
        {showData.dbId > 0 && (
          <Modal.Title id="modal-contribution">Edit Valuation - {showData.dbId}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="Values.Date">
            <Form.Label>Date of Valuation:</Form.Label>
            <Form.Control 
              type="date" 
              name="date"
              value={dateFormat(formik.values.date, "yyyy-mm-dd")} 
              onChange={formik.handleChange} 
              isInvalid={!!formik.errors.date}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.date}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="values.CurrentValue">
            <Form.Label>Value at Date:</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                <Form.Control 
                    className='financial-input'
                    type="number" 
                    name="value"
                    value={formik.values.value} 
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.value} 
                />                                            
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {formik.errors.individual}
            </Form.Control.Feedback>     
          </Form.Group>
        </Form>
      </Modal.Body>
      {saving == false && (
        <Modal.Footer>
            <Button disabled={!formik.isValid} variant="primary" onClick={() => handleSubmit(formik.values)}>Record</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      )}
      {saving == true && (
        <Modal.Footer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        </Modal.Footer>
      )}
    </Modal>      
  )
}