import { useState, useEffect } from 'react';
import { Spinner, Form, Modal, Button, InputGroup, Col, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import dateFormat from 'dateformat';
import {useFormik} from 'formik';
import * as yup from 'yup';

export const PensionContribution = ({showData, handleCancel}) => { 
  const [saving, setSaving] = useState(false);

  const recordPensionContributions = async (dataToRecord) => {  
    setSaving(true);

    const contributeResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Pension/Contribution', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(dataToRecord),
    })
    .then((response) => {
      setSaving(false);
      handleCancel(true);
      return true;
    })    
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
      setSaving(false);
    });                
    
    setSaving(false);

  }

  const formatLabel = (value) => {
    // If using moment.js
    return "£" + value.toFixed(2).toString();
  }

  const handleSubmit= (values) => {
  
    var newDatatype = {
        id: showData.dbId,
        date: dateFormat(values.date, 'yyyy-mm-dd'),
        emp: values.employer,
        ind: values.individual
    }

    recordPensionContributions(newDatatype);    
  }

  useEffect(()=> {
    formik.initialValues.date = dateFormat(showData.contributionDate, 'yyyy-mm-dd');
    formik.initialValues.employer = showData.employer;
    formik.initialValues.individual = showData.individual;
    formik.resetForm();

    //getPensionValues( readToken() );
  }, [showData]);

  const schema = yup.object().shape({
    date: yup.string()
        .required("Required"),
    employer: yup.string()
        .matches(/^\d+(?:\.\d{0,2})?$/, "Must only contain numeric characters")
        .required("Required"),
    individual: yup.string()
        .matches(/^\d+(?:\.\d{0,2})?$/, "Must only contain numeric characters")
        .required("Required"),    
  });

  const formik = useFormik({
    onSubmit: (values) => handleSubmit(values),
    validationSchema: schema,
    initialValues:{
      date: dateFormat(showData.contributionDate, 'yyyy-mm-dd'),
      employer: showData.employer,
      individual: showData.individual
    },
    
  });

  return (
    <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-contribution" >
      <Modal.Header closeButton>
        {showData.dbId == 0 && (
          <Modal.Title id="modal-contribution">Add Contribution</Modal.Title>
        )}
        {showData.dbId > 0 && (
          <Modal.Title id="modal-contribution">Edit Contribution - {showData.dbId}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="contribution.Date">
                        <Form.Label>Date of Contribution:</Form.Label>
                        <Form.Control type="date" 
                            name="date"
                            value={dateFormat(formik.values.date, "yyyy-mm-dd")} 
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.date}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.date}
                        </Form.Control.Feedback>
                    </Form.Group>          
                </Col>            
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="contribution.Employer">
                        <Form.Label>Employer Contribution:</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                            <Form.Control 
                                className='financial-input'
                                type="number" 
                                name="employer"
                                value={formik.values.employer} 
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.employer} 
                            />                            
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.employer}
                        </Form.Control.Feedback>
                    </Form.Group>                
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="contribution.Individual">
                        <Form.Label>Individual Contribution:</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2">£</InputGroup.Text>
                            <Form.Control 
                                className='financial-input'
                                type="number" 
                                name="individual"
                                value={formik.values.individual} 
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.individual} 
                            />                            
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.individual}
                        </Form.Control.Feedback>            
                    </Form.Group>
               </Col>
            </Row>
            <Row>
                <Col md={{span: 6, offset: 6}}>
                    <Form.Group className="mb-3" controlId="contribution.Total">
                        <Form.Label>Total Contribution:</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">£</InputGroup.Text>
                            <Form.Control 
                                className='financial-input'
                                type="text" 
                                value={formatLabel(Number(formik.values.individual) + Number(formik.values.employer))}  
                                readOnly
                            />
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
      </Modal.Body>
      {saving == false && (
        <Modal.Footer>
            <Button disabled={!formik.isValid} variant="primary" onClick={() => handleSubmit(formik.values)}>Record</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      )}
      {saving == true && (
        <Modal.Footer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Savings...</span>
          </Spinner>
        </Modal.Footer>
      )}
    </Modal>  
  )
}