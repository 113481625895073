import { useState, useEffect } from 'react';
import { Row, Col, Spinner, Form, Modal, Button, Alert, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {useFormik} from 'formik';
import * as yup from 'yup';


export const BankAccountUpdate = ({showData, handleCancel}) => {
    const [saving, setSaving] = useState(false);
    const [bankAccount, setBankAccount] = useState({
      id: 0,
      type: ""
    });
    const [errorMessage, setErrorMessage] = useState("");
  
    const recordData = async (record) => {

      var url = 'https://thepinkgiraffe.azurewebsites.net/Bank/Account';
  
      return fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
          'Origin': 'https://localhost:44386'        
        },
        body: JSON.stringify(record),
        }); 
    }

    const recordBankAccount = async (recordToSave) => {  
      setSaving(true);
      setErrorMessage("");
  
      var url = new URL("https://thepinkgiraffe.azurewebsites.net/Bank/Account");
  
      const bankAccountRec = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
          'Origin': 'https://localhost:44386'        
        },
        body: JSON.stringify(recordToSave),
      })
      .then((response) => {
        if(response.status == 200) {
          handleCancel(true);
          setSaving(false);
        } else {
          setErrorMessage("Bank account could not be inserted.");
          setSaving(false);
        }
      })
      .then(() => {
        //not sure if I need this
      })
      .catch((err) => {
        setErrorMessage(err);
        setSaving(false);
      });                
      
      setSaving(false);
    }
  
    useEffect(()=> {
      setBankAccount({...bankAccount,
        id: showData.dbId,
        type: showData.type
      });
  
      formik.initialValues.name = showData.name;
      formik.initialValues.accountno = showData.accountNo.trim();
      formik.initialValues.sortcode = showData.sortCode.trim();
      formik.initialValues.balance = showData.openingBalance;
      formik.resetForm();
    }, [showData]);
  
    const handleSubmit= async(values) => {
  
      var newDatatype = {
        id: bankAccount.id,
        name: values.name,
        sc: values.sortcode,
        acc: values.accountno,
        obal: values.balance,
        type: bankAccount.type,
        abal: 0.0
      }
  
      setSaving(true);
      setErrorMessage("");

      //wait for web-service call
      const updateResponse = 
        await Promise.all([
          recordData(newDatatype),
        ]);
              
      if(updateResponse != null && updateResponse.length > 0) {
        if(updateResponse[0].status == 200) {
          handleCancel(true);
          setSaving(false);
        } else {
          setErrorMessage("Bank account could not be inserted.");
          setSaving(false);
        }
      } else {
        setSaving(false);
      }
    }
  
    const schema = yup.object().shape({
      name: yup.string().max(50, "Must be 50 characters or less")
          .min(3, "Must be at least 3 characters")
          .required("Required"),
      sortcode: yup.string()
          .max(6, "Must be 6 characters or less")
          .matches(/^[0-9]+$/, "Must be contain numeric characters"),
      accountno: yup.string()
          .max(16, "Must be 16 characters or less")
          .min(8, "Must be at least 8 characters")
          .matches(/^[0-9]+$/, "Must be contain numeric characters")
          .required("Required"),
      balance: yup.number().required()    
    });
  
    const formik = useFormik({
      onSubmit: (values) => handleSubmit(values),
      validationSchema: schema,
      initialValues:{
        name: showData.name,
        sortcode: showData.sortCode.trim(),
        accountno: showData.accountNo.trim(),
        balance: showData.openingBalance,
      },
      
    });
  
    return (
      <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-bankaccount" >
        <Modal.Header closeButton>
          {showData.dbId > 0 && (
            <Modal.Title id="modal-bankaccount">Update Bank Account</Modal.Title>
          )}
          {showData.dbId == 0 && (
            <Modal.Title id="modal-bankaccount">Add Bank Account</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="account.name">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isValid={formik.touched.name && !formik.errors.name}
                    isInvalid={!!formik.errors.name}         
                  /> 
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
  
                <Form.Group className="mb-3" controlId="account.sortcode">
                  <Form.Label>Sort Code:</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="sortcode"
                    value={formik.values.sortcode} 
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.sortcode} 
                  />            
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.sortcode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="account.accountno">
                  <Form.Label>Account Number:</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="accountno"
                    value={formik.values.accountno} 
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.accountno} 
                  />            
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.accountno}
                  </Form.Control.Feedback>
                </Form.Group>         
              </Col>
            </Row>          
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="account.balance">
                  <Form.Label>Opening Balance:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                    <Form.Control 
                      className='financial-input'
                      type="number" 
                      name="balance"
                      value={formik.values.balance} 
                      onChange={formik.handleChange}
                      isInvalid={!!formik.errors.balance}  
                    />            
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.balance}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="account.type">
                  <Form.Label>Account Type:</Form.Label>
                  <Form.Select 
                    aria-label="Select Account Type" 
                    id="dropdown-accounttype" 
                    title="Account Type" 
                    defaultValue={showData.type}
                    onChange={(e) => setBankAccount({...bankAccount, 
                                                type: e.target.value})} >
                      <option key={1} value="Current">Current</option>
                      <option key={2} value="Savings">Savings</option>
                      <option key={3} value="Cheque">Cheque</option>
                      <option key={4} value="Credit Card">Credit Card</option>
                      <option key={5} value="Mortgage">Mortgage</option>
                      <option key={6} value="Investment">Investment</option>
                      <option key={7} value="Transaction">Transaction</option>
                  </Form.Select>                      
                </Form.Group>            
              </Col>
            </Row>
            {saving == false && (
              <Modal.Footer>
                <Button disabled={!formik.isValid} onClick={() => handleSubmit(formik.values)}>Record</Button>                                
                {' '}
                <Button variant="secondary" onClick={handleCancel}>Cancel</Button> 
              </Modal.Footer>
            )}
            {errorMessage != "" && (
              <Alert key='warning' variant='warning'>
                {errorMessage}
              </Alert>
            )}
            {saving == true && (
              <Modal.Footer>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Saving...</span>
                </Spinner>
              </Modal.Footer>
            )}
          </Form>
          
        </Modal.Body>
      </Modal> 
    )
  }