import { Component } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Badge, Container, Row, Col, Spinner, Navbar, Nav, Form, NavDropdown, Modal, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { CartesianGrid, XAxis, YAxis, Tooltip ,Legend, AreaChart, Area, ResponsiveContainer } from 'recharts';

import { AlertDismiss } from './AlertDismiss.js';
import dateFormat from 'dateformat';
import Table from "./Table.js";
import { now } from 'moment/moment.js';

import { PensionContribution } from './PensionContribution.js';
import { PensionValue } from './PensionValue.js';

export const PensionChart = ({CancelClick, Prices}) => { 

  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const formatLabel = (value) => {
    // If using moment.js
    return "£" + value.toFixed(2).toString();
  }

  const MyComponent = ({ xAxisProps: { dataKey: 'date', tick: { fontSize: 12 }, angle:90, tickMargin:30, height:100, tickFormatter:formatXAxis} });

  return (
    <>
    <ResponsiveContainer width="95%" height={300} >
      <AreaChart data={Prices} >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="val" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)"/>
        <CartesianGrid stroke="#ccc"/>
        <Tooltip formatter={formatLabel} labelFormatter={formatXAxis}/>
        <YAxis  />
        <XAxis {...MyComponent.xAxisProps}/>
      </AreaChart> 
    </ResponsiveContainer>
    <button onClick={(e) => CancelClick(e)} className='button'>Close</button> 
    </>
  )
}



export const PensionInformation = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [localToken, setLocalToken] = useState("");  
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias',
    loginToken: "8C986FA5-A72E-4EF9-A371-18F40F64D112"
  });
  const [updateContributions, setUpdateContributions] = useState(false);
  const [updateValues, setUpdateValues] = useState(false);

  const [showValuation, setShowValuation] = useState({
    dbId: 0,
    showModal: false,
    ValueDate: Date('2024-06-20'),
    Value: 0.00
  });

  const[showContributions, setShowContributions] = useState({
    dbId: 0,
    showModal: false,
    contributionDate: Date('2024-08-15'),
    employer: 0.0,
    individual: 0.0,
    total: 0.0
  });

  const[showGraph, setShowGraph] = useState(true);
  const[showModal, setShowModal] = useState({
    Type: 0, //type 0 = contribution, 1 = value
    Id: 0,
    DbId: 0,
    Dd: Date('2024-01-01'),    
    Show: false
  });

  const [contributions, setContribution] = useState([]);
  const [pensionValues, setPensionValues] = useState([]);

  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
     
    if(tempToken == null) {
      setToken("blank");
    } else {
      setLocalToken(tempToken);
    }
    return tempToken;
  }

  const readUser = () => {
    var tempUser = sessionStorage.getItem("loggedUser");
     
    return tempUser;
  }

  const deletePension = async (id) => {
    var output = false;
    var url = new URL("https://thepinkgiraffe.azurewebsites.net/Pension/Delete/");
    url.searchParams.append("pensionID", id);

    const valueResponse = await fetch(url, {
      method: 'DELETE',      
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + readToken(),
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => {
        output = true;
        return true;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });   
      
    return output;
  }

  const deletePensionValue = async (id) => {
    var output = false;
    var url = new URL("https://thepinkgiraffe.azurewebsites.net/Pension/DeleteValue/");
    url.searchParams.append("pensionID", id);

    const valueResponse = await fetch(url, {
      method: 'DELETE',      
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + readToken(),
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => {
        output = true;
        return true;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });   
      
    return output;
  }

  const getPensionValues = async (e) => {

    const valueResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Pension/Values', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json() )    
      .then((data) => {
        setPensionValues(data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });    
  }

  const getPensionContributions = async (e) => {

    const contributeResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Pension/Contributions', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json() )    
      .then((data) => {
        setContribution(data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });   
      
      getPensionValues( readToken() );      
  }

  const addContribution = () => {
    if(contributions != null &&  contributions.length > 0) {
      var currentCont = contributions[contributions.length-1];

      setShowContributions({...showContributions,
        dbId: 0,
        showModal: true,
        contributionDate: currentCont.contribution,
        employer: currentCont.employer,
        individual: currentCont.individual,
        total: currentCont.total
      });
    } else {
      setShowContributions({...showContributions,
        showModal: true
      });
    }
  }

  const addValuation = () => {
    setShowValuation({...showValuation,
      dbId: 0,
      Value: 0,
      ValueDate: dateFormat(now(), "yyyy-mm-dd"),
      showModal: true
    });
  }

  const handleValueCancel = (reset = false) => {
    setShowValuation({...showValuation,
      showModal: false
    });

    if(reset === true) {
      getPensionValues( readToken());
    }
  }

  const handleCancel = (reset = false) => {
    setShowContributions({...showContributions,
      showModal: false
    });

    if(reset === true) {
      getPensionContributions( readToken());
    }
  }

  const handleChartTrigger = (e) => {
    e.preventDefault();
    
    setShowGraph(!showGraph);
  }

  const confirmDelete = (type, id) => {

    setShowModal({
      Type: type,
      Id: id,
      DbId: (type == 0)? contributions[id].id : pensionValues[id].id,
      Dd: (type == 0)? contributions[id].date: pensionValues[id].date,
      Show: true
    });
  }

  const confirmEdit = (type, id) => {
    if(contributions != null &&  contributions.length > id) {
      var currentCont = contributions[id];

      setShowContributions({...showContributions,
        dbId: currentCont.id,
        showModal: true,
        contributionDate: currentCont.date,
        employer: currentCont.emp,
        individual: currentCont.ind,
        total: currentCont.tot
      });
    }
  }

  const confirmValueEdit = (type, id) => {
    if(pensionValues != null &&  pensionValues.length > id) {
      var currentVal = pensionValues[id];

      setShowValuation({...showValuation,
        dbId: currentVal.id,
        Value: currentVal.val,
        ValueDate: currentVal.date,
        showModal: true
      });
    }
  }

  const deleteRecord = async () => {

    var deleted = false;
    //delete the record here
    if(showModal.Type == 0)
    {
      var deleted = await deletePension(showModal.DbId);
    } else {
      var deleted = await deletePensionValue(showModal.DbId);
    }

    if(deleted == true) {
      //hide the confirmation box
      hideModal();

      if(showModal.Type == 0) {
        //reload the data after delete
        getPensionContributions( readToken());      
      } else {
        getPensionValues(readToken());
      }
    } else {
      //hide the confirmation box
      hideModal();
      //show the error to the audience
      setAlertMessage("Error while deleting");
      setMessageShow(true);
    }
   
  }

  const getBody = () => {

    if(showModal.Type == 0) {
      if(contributions != null && contributions.length > 0  && showModal.Id >= 0 && contributions.length > showModal.Id) {
        return (
          <Modal.Body>
            <p>Are you sure you want to delete the record dated
            </p>
            {' '}
            {dateFormat(showModal.Dd, 'yyyy/mm/dd')} 
            {', with total contribution of '}
            {contributions[showModal.Id].totalStr}
            {'?'}
          </Modal.Body>
        )
      }
    } else {
      if(pensionValues != null && pensionValues.length > 0  && showModal.Id >= 0 && pensionValues.length > showModal.Id) {
        return (
          <Modal.Body>
            <p>Are you sure you want to delete the value record dated
            </p>
            {' '}
            {dateFormat(showModal.Dd, 'yyyy/mm/dd')} 
            {', with total valuation of £'}
            {pensionValues[showModal.Id].value}
            {'?'}
          </Modal.Body>
        )
      }
    }

  }

  const hideModal = (reset = false) => {
    setShowModal({...showModal, Show: false});
  } 

  //only run on first render
  useEffect(()=> {
    getPensionContributions( readToken());
    //getPensionValues( readToken() );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Pension",
        columns: [
          {
            Header: "Contribution",
            accessor: "date",
            Cell: ({cell : { value }}) => <>{dateFormat(value, "yyyy/mm/dd")}</> 
          },

        ],
      },
      {
        Header: "Contribution",        
        columns: [
          {
            Header: "Employer",
            accessor: "emps",
            className: "contributionEmployerColumn",
            Cell: ({cell}) => <Badge bg="secondary">{cell.value}</Badge> 
          },
          {
            Header: "Employee",
            accessor: "inds",
            className: "contributionIndividualColumn",
            Cell: ({cell : { value }}) => <Badge bg="secondary">{value}</Badge> 
          },
          {
            Header: "Total",
            accessor: "tots",
            Cell: ({cell : { value }}) => <Badge bg="success">{value}</Badge> 
          },
          {
            Header: "Accumulated",
            accessor: "rtots",
            Cell: ({cell}) => <Badge bg="info">{cell.value}</Badge> 
          },
          {
            Header: "Actions",
            Cell: ({row}) => <>
              <img src="../image/edit2.png" alt="edit" onClick={() => confirmEdit(0, row.index)} width={32} height={32}/>
              <img src="../image/delete.png" alt="delete" onClick={() => confirmDelete(0, row.index)} width={32} height={32}/>
              </>
          }
        ]
      }
    ]
  )

  const valueColumns = useMemo(
    () => [
      {
        Header: "Pension",
        columns: [
          {
            Header: "Date",
            accessor: "date",
            Cell: ({cell : { value }}) => <>{dateFormat(value, "yyyy/mm/dd")}</> 
          },

        ],
      },
      {
        Header: "Values",
        columns: [
          {
            Header: "Value",
            accessor: "vals",
            Cell: ({cell : { value }}) => <Badge bg="primary">{value}</Badge> 
          },
          {
            Header: "Overall Growth",
            accessor: "growths",
            Cell: ({cell : { value }}) => <Badge bg="secondary">{value}</Badge> 
          },
          {
            Header: "Annual Growth",
            accessor: "annuals",
            Cell: ({cell : { value }}) => <Badge bg="success">{value}</Badge> 
          },
          {
            Header: "Actions",
            Cell: ({row}) => <>
              <img src="../image/edit2.png" alt="edit" onClick={() => confirmValueEdit(1, row.index)} width={32} height={32}/>
              <img src="../image/delete.png" alt="delete" onClick={() => confirmDelete(1, row.index)} width={32} height={32}/>
              </>
          }
        ]
      }
    ]
  )  

  return (
    <>
      <div>
        <AlertDismiss heading="Information!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <div>
        <Navbar collapseOnSelect sticky="top" expand="sm" bg="dark" data-bs-theme="dark">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-pension" />
            <Navbar.Brand href="/pensioninformation">Pension</Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-pension">
              <Nav className="me-auto">
                <Nav.Link><div onClick={() => setUpdateContributions(!updateContributions)}>Show Contributions</div></Nav.Link>
                <Nav.Link><div onClick={() => setUpdateValues(!updateValues)}>Show Values</div></Nav.Link>
                <Nav.Link><div onClick={() => addContribution()}>Add Contribution</div></Nav.Link>
                <Nav.Link><div onClick={() => addValuation()}>Add Values</div></Nav.Link>
                {(!showGraph) && (<Nav.Link><div onClick={(e) => handleChartTrigger(e)}>Show Graph</div></Nav.Link> )}
                {(showGraph) && (<Nav.Link><div onClick={(e) => handleChartTrigger(e)}>Hide Graph</div></Nav.Link> )}
              </Nav>        
            </Navbar.Collapse>
           
          </Container>        
        </Navbar>
      </div>
      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Wealth</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Welcome {userData.alias}</Badge>
      </h2>
      <h3 style={{display: "grid", justifyContent: "center"}}>
        Pension
      </h3>
      <div>
        <Modal show={showModal.Show} onHide={hideModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete?</Modal.Title>
          </Modal.Header>

          {getBody()}

          <Modal.Footer>
            <Button variant="primary" onClick={deleteRecord}>Yes</Button>
            <Button variant="secondary" onClick={hideModal}>No</Button>
          </Modal.Footer>
        </Modal>

        {showContributions != null && (
          <PensionContribution showData={showContributions} handleCancel={handleCancel}/>
        )}

        {showValuation != null && (
          <PensionValue showData={showValuation} handleCancel={handleValueCancel}/>
        )}

        <Container fluid="md" >  
          <Row>
            <Col md={{ span: 12, offset: 0}}>
              <center>
                {pensionValues != null && pensionValues.length > 0 && (
                  <>
                    {(showGraph) && <PensionChart CancelClick={handleChartTrigger} Prices={pensionValues}/> }
                  </>
                )}
                {pensionValues == null || pensionValues.length == 0 && showGraph == true && (
                  <center>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </center>
                )}
              </center>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            {updateContributions && (
            <Col xs={12} md={12} lg={8}>
              {contributions != null && contributions.length > 0 && (
                <Table columns={columns} data={contributions} />
              )}
              {(contributions == null || contributions.length == 0) && (
                <center>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </center>
              )}
            </Col>
            )}
            {updateValues && (
            <Col xs={12} md={12} lg={4}>
              {pensionValues != null && pensionValues.length > 0 && (
                <Table columns={valueColumns} data={pensionValues} />
              )}
              {(pensionValues == null || pensionValues.length == 0) && (
                <center>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </center>
              )}              
            </Col>
            )}
          </Row>

        </Container>
      </div>
    </>
  );
  
}