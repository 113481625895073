import { Component } from 'react';
import { useState, useEffect } from 'react';
import { Badge, OverlayTrigger, Tooltip as RTooltip, Container, Row, Col, Spinner, Navbar, Nav, Form, NavDropdown, Modal, Button, ButtonGroup, Alert, Accordion, ToggleButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, AreaChart, Area, ResponsiveContainer, ReferenceDot } from 'recharts';
import { AlertDismiss } from './AlertDismiss.js';
import dateFormat from 'dateformat';
import moment from 'moment';
import { Moment, now } from 'moment/moment.js';

import { ShareUpdate } from './ShareUpdate.js';
import { ShareQuantityUpdate } from './ShareQuantityUpdate.js';

export const ShareInformation = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias',
    loginToken: "8C986FA5-A72E-4EF9-A371-18F40F64D112"
  });

  const [posts, setPosts] = useState([]);
  const [toggled, setToggled] = useState([]);
  const [showValues, setShowValues] = useState(true);
  const [showPrices, setShowPrices] = useState(false);
  const [displayType, setDisplayType] = useState(1);
  const [startDate, setStartDate] = useState(dateFormat(now(), "yyyy-mm-dd"))
  const [endDate, setEndDate] = useState(dateFormat(now(), "yyyy-mm-dd"))
  const [applyDates, setApplyDates] = useState(false)
  const [refreshData, setRefreshData] = useState(0)
  const [selectAll, setSelectAll] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [periodSelect, setPeriodSelect] = useState(4);
  const [showShareQtyUpdate, setShowShareQtyUpdate] = useState({
    dbId: 0,
    showModal: false,
    shareName: "",
    maxShares: 0
  });

  const [showShareUpdate, setShowShareUpdate] = useState({
    dbId: 0,
    showModal: false,
    name: "",
    url: "",
    elementPath: "",
    amountHeld: 0,
    displayColour: 0,
    symbol: ""
  });

  
  const readToken = () => {
    return sessionStorage.getItem("playerInfo");     
  }

  const handleRedirect = () => {    
    navigate("/login");
  }

  const calculateYear = () => {
    var date = new Date(now());

    date.setFullYear(date.getFullYear() - 1);
    return dateFormat(date, "yyyy-mm-dd");
  }
  
  const setDateRange = (diff, index) => {
    var curdate = new Date(now());
    var month = curdate.getMonth();
    var year = curdate.getFullYear();
    var day = curdate.getDate(); //gets the day of the month

    if(month - diff < 0) {
      year = year - 1;
      month = ((12 + month) - diff);
    } else {
      month = month - diff;
    }

    var newDate = new Date(now());
    //set the year and month
    newDate.setFullYear(year);
    newDate.setMonth(month, day);

    curdate.setMonth(curdate.getMonth(), day + 1);

    setStartDate(dateFormat(newDate, "yyyy-mm-dd"));
    setEndDate(dateFormat(curdate, "yyyy-mm-dd"));
    setApplyDates(true);
    setPeriodSelect(index);
  }

  const updateShares = async (e) => {
    e.preventDefault();

    setRefreshData(1);

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/Share', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + readToken(),
        'Origin': 'https://localhost:44386'        
      }
    })
      .then((response) => response.json() )    
      .then((data) => {
        console.log(data);    
        setMessageShow("Share values updated.");    
        setDisplayType(1);
        setRefreshData(0);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
        clearToken();
        setAlertMessage("");
        handleRedirect();
      });    
  }
 
  const formatLabel = (value) => {
    if(displayType == 0){
      return value.toFixed(2).toString();       
    } else {
      // If using moment.js
      return "£" + value.toFixed(2).toString();
    }
  }
  
  const formatCurrency = (value) => {
      return "£" + value.toFixed(2).toString();
  }

  const formatFloat = (value) => {
    return value.toFixed(2).toString();       
  }

  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const formatDateTooltip = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm/dd");
  }

  const renderLines = (data) => {
    var i = 0;
        
    const lines = data.map((item) => {  
      if(!toggled.some(i => {if(i == item.share.id) { return true; } return false})) {
        if(showValues) {
          return (
            <Line key={item.share.id} name={item.share.name} type="monotone" dataKey={item.share.name} stroke={item.share.c} />
          )  
        }

        if(showPrices) {
          return (
            <Line key={item.share.id} name={item.share.name} type="monotone" dataKey={item.share.name + "p"} stroke={item.share.c} />
          )  
        }
      }
    });
    return lines;    
  }

  const getShares = async (e) => {
    var url = 'https://thepinkgiraffe.azurewebsites.net/Share/Shares';

    if(displayType == 0) {
      url = 'https://thepinkgiraffe.azurewebsites.net/Share/Zero'
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json() )    
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
        clearToken();
        handleRedirect();
      });    
  }

  const createArray = (posts) => {
    const lineGraph = []
    
    posts.map((post) => {    
      //if this item is selected for calculation
      if(isChecked(post.share.id)) {
        //loop through the prices
        post.prices.map((dataitem) => {
          //if included in date range (only if "apply dates" has been selected)
          if((applyDates && dataitem.pd >= startDate && dataitem.pd <= endDate) || (applyDates == false)) {
            //if this date is already in the list
            if(lineGraph.some(i => { if(i.date === dataitem.pd) { return true; } return false}) == false ) {
              const obj = {};
              obj["date"] = dataitem.pd;            
              obj[post.share.name] = dataitem.v; 
              obj[post.share.name + "p"] = dataitem.p;
              lineGraph.push(obj);           
            } else {
              for(let i = 0; i < lineGraph.length; i++) {
                if(lineGraph[i]["date"] === dataitem.pd) {
                  lineGraph[i][post.share.name] = dataitem.v;
                  lineGraph[i][post.share.name + "p"] = dataitem.p;
                  break;
                }
              } //for        
            } //if-else 
          } //if - date range
        }) //loop - prices 
      } //if - toggled
    }); //loop 
    
    return lineGraph;
  }

  const calculateStats = () => {
    const stats = [];
    posts.map((post) => {    
      stats.push({
        id: post.id,
        min: 999999,
        datemin: null,
        minValue: 0,
        max: -100,
        datemax: null,
        maxValue: 0
      })
    })    

    posts.map((post, i) => {    
      //if this item is selected for calculation
      if(isChecked(post.share.id)) {  
        //loop through the prices
        post.prices.map((dataitem) => {
          //if included in date range (only if "apply dates" has been selected)
          if((applyDates && dataitem.pd >= startDate && dataitem.pd <= endDate) || (applyDates == false)) {
            //save the maximum
            if(dataitem.p > stats[i].max) {
              stats[i].max = dataitem.p;
              stats[i].maxValue = dataitem.v;
              stats[i].datemax = dataitem.pd;
            }
            //save the minimum
            if(dataitem.p < stats[i].min) {
              stats[i].min = dataitem.p;
              stats[i].minValue = dataitem.v;
              stats[i].datemin = dataitem.pd;
            }
          }
        });
      }
    });

    return stats;
  }


  const createOverallArray = (posts) => {
    const lineGraph = []
    
    posts.map((post) => {    
      if(isChecked(post.share.id)) {
        post.prices.map((dataitem) => {
          //if included in date range (only if "apply dates" has been selected)
          if((applyDates && dataitem.pd >= startDate && dataitem.pd <= endDate) || (applyDates == false)) {
            var value = dataitem.v;

            if(displayType == 0) {
                value = dataitem.p - 100;
            }

            if(lineGraph.some(i => { if(i.date === dataitem.pd) { return true; } return false}) == false ) {
              const obj = {};
              obj["date"] = dataitem.pd;            
              obj["amount"] = value; 
              lineGraph.push(obj);           
            } else {
              for(let i = 0; i < lineGraph.length; i++) {
                if(lineGraph[i]["date"] === dataitem.pd) {
                  lineGraph[i]["amount"] += value;
                  break;
                }
              }         
            }
          }
        });
      }
    });

    return lineGraph;
  }

  const isChecked = (id) => {
    return !toggled.some(i => i === id);
  }

  const toggleLines = (id) => {
    //make a copy
    var newArray = toggled.slice();

    if(!newArray.some(i => i === id)) {
      //add the extra one
      newArray.push(id);
      setSelectAll(false);
    } else {
      newArray = [];
      //add all except the toggled one
      toggled.map((i) => {
        if(i != id) {
          newArray.push(i);
        }
      });
    }

    setToggled(newArray);
  }

  const toggleSelectAll = (value) => {
    if(value == false) {
      //make a copy
      var newArray = [];
    
      toggled.map((i) => {
        newArray.push(i);
      })

      setToggled(newArray);
    } else {
      var newArray = [];
    
      setToggled(newArray);
    }

    setSelectAll(value);
  }

  const className = (value) => {
    if((value[0] == '-') || (value < 0)) {
      return "loss"
    } else {
      return "gain"
    }
  }

  const shareNameLabel = (post, checked, checkbox) => {
    return (
      <OverlayTrigger
                      placement="top"
                      delay={{show: 250, hide: 400}}
                      overlay={renderTooltip(post)}>
        <label className="shareName">
          {checkbox && (
            <input id={post.id} type="checkbox" checked={checked} onChange={() => toggleLines(post.id)}/>
          )}

          {' '}{post.name}                      
             
        {post.share.sym != "" && (
          <>
          {' '}
          <span className="symbol" style={{backgroundColor: post.share.c}}>{post.share.sym}</span>
          </>
        )}
        </label>
      </OverlayTrigger>
    )
  }

  const renderShareTable = (props) => {
    var totalStart = 0.0;
    var totalEnd = 0.0;

    return (
      <table className='financial-table'>
        <thead>
          <tr>
            <td rowSpan={2}>
              Shares
            </td>
            <td colSpan={2}>
              Value
            </td>
            <td colSpan={2} className="sharesPriceColumn">
              Price
            </td>
            <td colSpan={2}>
              Diff
            </td>
          </tr>
          <tr>
            <td>
              Start
            </td>
            <td>
              End
            </td>
            <td className="sharesPriceColumn">
              Start
            </td>
            <td className="sharesPriceColumn">
              End
            </td>
            <td>
              Value
            </td>
            <td>
              {(displayType == 1)? "Price": "Perc %"}
            </td>
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => {
            var checked = isChecked(post.id);
            var startPrice = post.prices[0].p;
            var endPrice = post.prices[post.prices.length-1].p;
            var startValue = post.prices[0].v;
            var endValue = post.prices[post.prices.length-1].v;

            if(applyDates) {
              let output = post.prices.filter( item => item.pd >= startDate && item.pd <= endDate);

              if(output != null && output.length > 0) {
                startPrice = output[0].p;
                endPrice = output[output.length-1].p;  
                startValue = output[0].v;
                endValue = output[output.length-1].v;  
              } else {
                checked = false;
              }
            }

            if(checked) {

              totalStart += startValue;
              totalEnd += endValue;

              return (
                <tr key={post.id} className="dataValue" >
                  <td className="headerValue">
                    {shareNameLabel(post, true, false)}
                  </td>
                  <td className="sharePriceToday">            
                    {formatCurrency(startValue)}
                  </td>
                  <td className="sharePriceToday">            
                    {formatCurrency(endValue)}
                  </td>
                  <td className="sharesPriceColumn">            
                    {formatFloat(startPrice)}
                  </td>
                  <td className="sharesPriceColumn">
                    {formatFloat(endPrice)}                 
                  </td>
                  <td className="sharePriceToday">
                    {formatCurrency(endValue - startValue)}
                  </td>
                  <td className="sharePriceToday">
                    {formatFloat(endPrice / startPrice * 100.0)}
                  </td>
                </tr>
              )  
            } else {
              return (<></>)
            }
         })}
        </tbody>
        <tfoot>
          <tr>
            <td>
            </td>
            <td className='overall'>
              {formatCurrency(totalStart)}
            </td>
            <td className='overall'>
              {formatCurrency(totalEnd)}
            </td>
            <td colSpan={2} className="sharesPriceColumn">

            </td>
            <td className='overall'>
              {formatCurrency(totalEnd - totalStart)}
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }

  const renderTooltip = (props) => (
    <RTooltip id="button-tooltip">
      <table className="financial-tooltip">
        <tbody>
          <tr>
            <td>
              Price since last:
            </td>
            <td className={className(props.psls)}>            
              {props.psls}
            </td>
          </tr>
          <tr>
            <td>
              Price since first:
            </td>
            <td className={className(props.psss)}>  
              {props.psss}
            </td>
          </tr>
          <tr>
            <td>
              Growth since last:
            </td>
            <td className={className(props.gsls)}>            
              {props.gsls}
            </td>
          </tr>
          <tr>
            <td>
              Growth since first:
            </td>
            <td className={className(props.gsss)}>  
              {props.gsss}
            </td>
          </tr>
          <tr>
            <td>
              Annualised (value):
            </td>
            <td className={className(props.avs)}>  
              {props.avs}
            </td>
          </tr>
          <tr>
            <td>
              Annualised (price):
            </td>
            <td className={className(props.aps)}>  
              {props.aps}
            </td>
          </tr>          
        </tbody>
      </table>
    </RTooltip>
  );

  const normalShareSelectionTable = (props) => (    
    <table className="financial-table">
      <thead>
        <tr>
          <td align='left'>  
            <label>
              <input id="selectall" type="checkbox" checked={selectAll} onChange={() => toggleSelectAll(!selectAll)}/>
              {' '}Shares
            </label>          
          </td>
          <td>
            Today
          </td>
          <td>
            Daily
          </td>
          <td>
            Overall
          </td>
          <td>

          </td>
        </tr>
      </thead>
      <tbody>
        {posts.map((post, i) => {
          var checked = isChecked(post.id);

          return (         
            
              <tr key={post.id}>
                <td style={{ padding: '5px', border: '1px', margin: '0 10px'}}>
                  {shareNameLabel(post, checked, true)}                                        
                </td>
                <td align='right' className="sharePriceToday"> 
                  {post.prices.length >= 2 && ( 
                    <>                  
                      <span>{formatFloat(post.prices[post.prices.length-1].p)}</span>
                    </>
                  )}
                </td>
                <td align='center'>
                  <span className={className(post.gsls)}>
                    <img className="arrow" src="../image/upp.webp" alt="profitlossl" />
                    {post.gsls
                  }</span>
                </td>
                <td align='center'>
                  
                  <span className={className(post.gsss)}>
                    <img className="arrow" src="../image/upp.webp" alt="profitlosss" />
                    {post.gsss}                                        
                  </span>
                </td>
                <td>
                  <img src="../image/trade2.jpg" alt="trade" onClick={() => openShareQtyEdit(posts, i)} width={28} height={28}/>
                  <img src="../image/edit2.png" alt="edit" onClick={() => openShareEdit(posts, i)} width={28} height={28}/>
                  {/* <img src="../image/delete.png" alt="delete" onClick={() => getShareData('REL')} width={32} height={32}/>  */}
                </td>
              </tr>
            
            )
        })} 
      </tbody>
    </table>
  )

  const zeroBasedShareList = (props) => ( 
    <table>
      <thead>
        <tr>
          <td>
            <label>
              <input id="selectall" type="checkbox" checked={selectAll} onChange={() => toggleSelectAll(!selectAll)}/>
              {' '}Shares
            </label>          
          </td>
          <td>
            Daily
          </td>
          <td>
            Overall
          </td>
        </tr>
      </thead>
      <tbody>
        {posts.map((post) => {
          var checked = isChecked(post.id);
          return (    
            <tr key={post.id}>
              <td style={{ padding: '5px', border: '1px', margin: '0 10px'}}>
                {shareNameLabel(post, checked, true)}                                        
              </td>
              <td>
                <span className={className(post.psls)}>
                  <img className="arrow" src="../image/upp.webp" alt="profitlosspl" />
                  {post.psls}
                </span>                                    
              </td>
              <td>
                <span className={className(post.psss)}>
                  <img className="arrow" src="../image/upp.webp" alt="profitlossps" />
                  {post.psss}
                </span>  
              </td>
              <td>
                <img src="../image/trade2.jpg" alt="trade" onClick={() => openShareQtyEdit(posts, i)} width={28} height={28}/>
                <img src="../image/edit2.png" alt="edit" onClick={() => openShareEdit(posts, i)} width={28} height={28}/>
                {/* <img src="../image/delete.png" alt="delete" onClick={() => getShareData('REL')} width={32} height={32}/>  */}
              </td>                
            </tr>
          )
        })} 
      </tbody>
    </table>    
    
  )

  const openShareQtyEdit = (posts, id) => {

    if(posts != null &&  posts.length > id) {
      var currentPost = posts[id];

      setShowShareQtyUpdate({...showShareQtyUpdate,
        showModal: true,
        dbId: currentPost.id,
        shareName: currentPost.name,
        maxShares: currentPost.share.ah
      });
    }
  }

  const handleShareQtyCancel = (reset = false) => {
    setShowShareQtyUpdate({...showShareQtyUpdate,
      showModal: false
    });

    if(reset === true) {
      //getPensionValues( readToken());
    }
  }

  const openShareEdit = (posts, id) => {

    if(posts != null &&  posts.length > id) {
      var currentPost = posts[id];

      setShowShareUpdate({...showShareUpdate,
        showModal: true,
        dbId: currentPost.id,
        name: currentPost.name,
        elementPath: currentPost.share.ep,
        url: currentPost.share.url,
        amountHeld: currentPost.share.scas, //share count at start
        displayColour: currentPost.share.c,
        symbol: currentPost.share.sym
      });
    }
  }

  const handleShareCancel = (reset = false) => {
    setShowShareUpdate({...showShareUpdate,
      showModal: false
    });

    if(reset === true) {
      getShares( readToken());
    }
  }

  const individualShareGraph = () => {
    return (
      <>
        {posts != null && posts.length > 0 && (            
          <ResponsiveContainer key="sharegraph" width="95%" height={400} >
            <LineChart margin={{ top: 5, right: 5, left: 5, bottom: 5 }} data={createArray(posts)} >
              {renderLines(posts)}
              <CartesianGrid stroke="#ccc"/>
              {showLegend == true && (
                <Legend verticalAlign="top" height={50} /> 
              )}
              <Tooltip formatter={formatLabel} labelFormatter={formatDateTooltip}/>
              <YAxis />
              <XAxis {...MyComponent.xAxisProps}/>
              {calculateStats().map((data) => {
                return (
                  <>
                    <ReferenceDot x={data.datemax} y={data.maxValue} label="" fill="green" r={5} />
                    <ReferenceDot x={data.datemin} y={data.minValue} label="" fill="red"  r={5}/>
                  </>
                )
              })}
            </LineChart>
          </ResponsiveContainer>
        )}
        {(posts == null || posts.length == 0) && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </>
    )
  }

  const overallShareGraph = () => {
    return (
      <>
        {posts != null && posts.length > 0 && (
          <ResponsiveContainer width="95%" height={400} >
            <AreaChart data={createOverallArray(posts)} >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <Area name="Total" type="monotone" dataKey="amount" stroke="#0088FE" fillOpacity={1} fill="url(#colorUv)"/>
              <CartesianGrid stroke="#ccc"/>
              {showLegend == true && (
                <Legend verticalAlign="top" height={50} /> 
              )}
              <Tooltip formatter={formatLabel} labelFormatter={formatDateTooltip}/>
              <YAxis />
              <XAxis {...MyComponent.xAxisProps}/>
            </AreaChart>
          </ResponsiveContainer>
        )}
        {(posts == null || posts.length == 0) && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}      
      </>
    )
  }

  //only run on first render
  useEffect(()=> {
    setPosts([]);
    getShares( readToken());
    setStartDate(calculateYear());
  }, [displayType]);


  const MyComponent = ({ xAxisProps: { dataKey: 'date', tick: { fontSize: 12 }, angle:90, tickMargin:30, height:100, tickFormatter:formatXAxis} });

  return (
    <>
      <div>
        <AlertDismiss heading="Information!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Wealth</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Welcome {userData.alias}</Badge>
      </h2>
      <h3 style={{display: "grid", justifyContent: "center"}}>
        Your current porfolio
      </h3>
      <h4>

      </h4>
      {showShareQtyUpdate.showModal && (
          <ShareQuantityUpdate showData={showShareQtyUpdate} handleCancel={handleShareQtyCancel}/>
      )}
      {showShareUpdate.showModal && (
          <ShareUpdate showData={showShareUpdate} handleCancel={handleShareCancel}/>
      )}


      <Container fluid="md">  
        <Row>
          <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
            {(displayType == 1) && normalShareSelectionTable()}
            
            {(displayType == 0) && zeroBasedShareList()}
          </Col> 
          <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
            {posts != null && posts.length > 0 && (
              renderShareTable()
            )}
            {(posts == null || posts.length == 0) && (
              <center>
                <Spinner animation="border" role="status">
                  {/* <img src="./image/minus.png" width={35} height={35} /> */}
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </center>
            )}
          </Col>             
        </Row>
        <Row>
          <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form>
              <Form.Group className="mb-3" controlId="share.pricevalue">
                <center>
                  <ButtonGroup aria-describedby="valueHelpBlock" className="shareButtonGroup">                  
                    <Button variant="primary" onClick={() => setShowValues(!showValues)}>Values</Button>
                    <Button variant="secondary" onClick={() => setShowPrices(!showPrices)}>Prices</Button>
                  </ButtonGroup>
                </center>
                <center>
                  <Form.Text id="valueHelpBlock" muted>
                    Either share values (price * quantity) or the price of a single share
                  </Form.Text>                
                </center>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form>
              <Form.Group className="mb-3" controlId="share.actualzero">
                <center>
                  <ButtonGroup aria-describedby="zeroHelpBlock">
                    <Button variant={(displayType == 1)? "primary": "secondary"} onClick={() => setDisplayType(1)}>Actual</Button>
                    <Button variant={(displayType == 0)? "primary": "secondary"} onClick={() => setDisplayType(0)}>Zero Based</Button>
                  </ButtonGroup>
                </center>
                <center>
                  <Form.Text id="zeroHelpBlock" muted>
                    Actual share prices or zero based to determine growth of each share equally
                  </Form.Text>   
                </center>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        {periodSelect == 5 && (
        <Row  >
          <Col sm={12} 
            md={{span: 4, offset: 0}} 
            lg={{span: 3, offset: 2}} 
            xxl={{span: 3, offset: 3}}>
            <center>
              <label>
                From:
              </label>
              {' '}
              <input type="date" value={startDate} onChange={(e) => setStartDate(dateFormat(e.target.valueAsDate, "yyyy-mm-dd"))}/>
            </center>
          </Col>
          <Col sm={12} 
            md={{span: 4, offset: 0}} 
            lg={{span: 3, offset: 0}} 
            xxl={{span: 2, offset: 0}}>
            <center>
              <label>
                To:
              </label>
              {' '}
              <input type="date" value={endDate} onChange={(e) => setEndDate(dateFormat(e.target.valueAsDate, "yyyy-mm-dd"))}/>
            </center>
          </Col>
          <Col sm={12} 
            md={{span: 4, offset: 0}} 
            lg={{span: 2, offset: 0}} 
            xxl={{span: 2, offset: 0}} >
            <center>
              <label>
                <input id="useDates" type="checkbox" checked={applyDates} onChange={() => setApplyDates(!applyDates)}/> 
                {' '}
                Apply dates
              </label>
            </center>
          </Col>
        </Row>
        )}              
        <Row>
          <Col className="shareGraphDisplaySmall" sm={12} md={12} >
            <Accordion defaultActiveKey="0" >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Individual Shares</Accordion.Header>
                <Accordion.Body>
                  {individualShareGraph()}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Overall</Accordion.Header>
                <Accordion.Body>
                  {overallShareGraph()}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col className="shareGraphDisplayLarge" lg={6} xl={6} xxl={6}>
            {individualShareGraph()}
          </Col>
          <Col className="shareGraphDisplayLarge" lg={6} xl={6} xxl={6} >
            {overallShareGraph()}
          </Col>          
        </Row>
        <Row>
          <Col>
            <center>
              <ButtonGroup size="sm">
                <ToggleButton type="radio" variant="outline-success" name="radio" id="radio-0" checked={periodSelect === 0} value={0} onChange={(e) => setDateRange(1, parseInt(e.currentTarget.value))}>1 Month</ToggleButton>
                <ToggleButton type="radio" variant="outline-success" name="radio" id="radio-1" checked={periodSelect === 1} value={1} onChange={(e) => setDateRange(3, parseInt(e.currentTarget.value))}>3 Months</ToggleButton>
                <ToggleButton type="radio" variant="outline-success" name="radio" id="radio-2" checked={periodSelect === 2} value={2} onChange={(e) => setDateRange(6, parseInt(e.currentTarget.value))}>6 Months</ToggleButton>
                <ToggleButton type="radio" variant="outline-success" name="radio" id="radio-3" checked={periodSelect === 3} value={3} onChange={(e) => setDateRange(12, parseInt(e.currentTarget.value))}>Year</ToggleButton>
                <ToggleButton type="radio" 
                  variant="outline-success" 
                  name="radio"
                  id="radio-4" 
                  checked={periodSelect === 4} 
                  value={4} 
                  onChange={(e) => {
                      setPeriodSelect(parseInt(e.currentTarget.value));
                      setApplyDates(false);
                      }}>
                  Max
                </ToggleButton>
              </ButtonGroup>
            </center>
          </Col>
        </Row>
        <Row>
          <Col>
            {refreshData == 0 && (
              <OverlayTrigger 
                delay={{show: 250, hide: 400}}
                overlay={<RTooltip id="button-tooltip-2">Retrieve latest prices</RTooltip>}>
                <button onClick={(e) => updateShares(e)}>Retrieve</button> 
              </OverlayTrigger>
            )}
            {refreshData == 1 && (
              <Spinner animation="border" role="status">
                <span>Loading...</span>
              </Spinner>             
            )}
            {posts != null && posts.length > 0 && (
              <p style={{fontSize: 10}}>
                Last updated: 
                {' '}
                {posts[0].prices[posts[0].prices.length-1].pd}
              </p>
              )}
            
          </Col>
          <Col>

          </Col>
        </Row>
      </Container>
    </>
  );
  
}