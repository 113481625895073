import { useState, useEffect, useRef } from 'react';
import { Row, Col, Spinner, Form, Modal, Button, Alert, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import dateFormat from 'dateformat';
import { Moment, now } from 'moment/moment.js';
import {useFormik} from 'formik';
import * as yup from 'yup';

export const AutoComplete = ({initialValue, possibleValues, onChange, onSelection}) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [localValues, setLocalValues] = useState([]);
  const [noteError, setNoteError] = useState(null);
  const noteInput = useRef(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length > 0) {
      const filteredSuggestions = localValues.filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }

    onChange(value);
  }

  const findCategoryByNote = (note) => {
    var index = possibleValues.find((item) => item.note == note);

    if(index != null) {
      return index.cat;
    } else {
      return 0;
    }
  }

  const handleSuggestionClick = (suggested) => {
    setInputValue(suggested);    
    var catId = findCategoryByNote(suggested);
    setSuggestions([]);
    //call calling method to notifi
    onSelection(catId);

    //noteInput.setErrors({ 'custom': true});
    noteInput.current.focus();
    onChange(suggested);
  }
    //only run on first render
  useEffect(()=> {
    var newList = [];

    setInputValue(initialValue);

    possibleValues.map((item) => {
      //only add unique values
      if(!newList.some(i => i == item.note)) {
        newList.push(item.note);
      }
    })

    setLocalValues(newList);
    
  }, [initialValue]);

  return (
    <div className="autocomplete-wrapper">
      <Form.Group className="mb-3" controlId="transactions.note">
        <Form.Label>Note:</Form.Label>
        <Form.Control 
          type="text" 
          name="note"
          ref={noteInput}
          value={inputValue}
          onChange={handleInputChange}
          aria-autocomplete="list"
          aria-controls="autocomplete-list"
          isInvalid={(noteError != null)}
        />            
        {suggestions.length > 0 && (
          <ul id="autocomplete-list" className="suggestions-list" role="listbox">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                role="option"
                // Additional props
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )} 
        <Form.Control.Feedback type="invalid">
          {noteError}
        </Form.Control.Feedback>
      </Form.Group> 
    </div>
  );
}

export const TransactionUpdate = ({showData, handleCancel, categories, bankAccounts, baseTransaction, setBaseTransaction, notes}) => {
    const [saving, setSaving] = useState(false);
    const [transfer, setTransfer] = useState(false);
    const [transferBankAccount, setTransferBankAccount] = useState(0);
    const [transaction, setTransaction] = useState({
      id: 0,
      transferId: 0,
      note: "",
      category: 0
    });
    const [errorMessage, setErrorMessage] = useState("");
    const selectRef = useRef(null);
    const [addMore, setAddMore] = useState(false);
    const [updateMade, setUpdateMade] = useState(false);
 
    const recordData = async (record) => {
      
      var url = 'https://thepinkgiraffe.azurewebsites.net/Bank/Transaction';
  
      return fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
          'Origin': 'https://localhost:44386'        
        },
        body: JSON.stringify(record),
        }); 
    }
 
    const formatMoney = (value) => {
      // If using moment.js
      return "£" + value.toFixed(2).toString();
    }

    const findCategoryById = (id) => {
      var index = categories.find((cat) => cat.id == id);
  
      if(index != null) {
        return index.cat;
      }
    }
  
    useEffect(()=> {
      setTransaction({...transaction,
        id: showData.dbId,
        transferId: showData.transferId,       
        note: showData.note
      });

      if(showData.id != 0) {
        setAddMore(false);
      }
      setTransfer( (showData.transferId > 0)? true: false);
      setTransferBankAccount(showData.transferId);
  
      formik.initialValues.type = showData.type;
      formik.initialValues.date = showData.date;
      formik.initialValues.amount = showData.amount;
      formik.initialValues.fid = showData.financialId;
      formik.initialValues.memo = showData.memo;
      formik.initialValues.total = showData.total;
      formik.initialValues.category = showData.category;
      formik.resetForm();
    }, [showData]);
    
    const clearData = () => {
      setTransaction({...transaction,
        note: showData.note
      });

      formik.initialValues.type = showData.type;
      formik.initialValues.date = values.date;
      formik.initialValues.amount = 0.0;
      formik.initialValues.fid = "";
      formik.initialValues.memo = "";
      formik.initialValues.total = 0.0;              
      formik.resetForm(); 
    }


    const handleSubmit= async(values) => {
      try
      {
        //indicate start of update
        setSaving(true);
        setErrorMessage("");

        var newDatatype = {
          id: showData.dbId,
          aid: parseInt(showData.accountId),
          type: values.type,
          date: values.date,
          amount: values.amount,
          fid: values.fid,
          memo: values.memo,
          memo2: showData.memo2,
          memo3: showData.memo3,
          cid: values.category,
          cat: findCategoryById(values.category),
          nts: transaction.note,
          tid: (transfer == true)? transferBankAccount: 0
        }
        
        if(newDatatype.cid > 0) {       
          //wait for web-service call
          const updateResponse = 
            await Promise.all([
              recordData(newDatatype),
            ]);

          if(updateResponse != null && updateResponse.length > 0) {
            
            if(updateResponse[0].status == 200) {          
              //check if there is a base transaction to update (i.e. less effort than to refresh)
              if(baseTransaction != null && 
                baseTransaction.length > showData.id && 
                showData.dbId != 0) {    //has to be an update, not an insert
              //only do this if we have an update
              setBaseTransaction(showData.id, newDatatype);  
              //can't be adding more as this is an edit
              handleCancel(false);
              } else {
                if(addMore == false) {
                  handleCancel(true);
                } else {
                  setUpdateMade(true);
                  //clear data and start to edit again
                  clearData();
                }
            }
            } else {
              setErrorMessage("Transaction could not be inserted.");
            }
          }
        } else {
          setErrorMessage("No category selected.");
        }
      } catch(error) {
        setErrorMessage(error.message);
      } finally {
        setSaving(false);
      }      
    }
  
    const showCategories = () => {
      var allowedType = 0;

      if(formik.values.amount < 0.0) {
        allowedType = 0;
      } else {
        allowedType = 1;
      }

      return categories.map((item) => {          
        //income / expense or a transfer          
        if(item.ct == allowedType || item.ct == 2) {

        if(item.pid == null) {
          return (
            <option key={item.id} value={item.id} >{item.cat}</option>
          )  
        } else {
          return (
            <option key={item.id} value={item.id} >{' -- '}{item.cat}</option>
          )        
        }
      }
      })
    }

    const onSelectionMade = (id) => {
      selectRef.current.value = parseInt(id);
      formik.values.category = id;
    }
      
    const schema = yup.object().shape({
      type: yup.string().max(50, "Must be 50 characters or less")
          .min(3, "Must be at least 3 characters")
          .required("Required"),
      date: yup.string()
          .required("Required"),
      amount: yup.number()
          .required("Required"),
      fid: yup.string(),
      memo: yup.string(),
    });
  
    const formik = useFormik({
      onSubmit: (values) => handleSubmit(values),
      validationSchema: schema,
      initialValues:{
        type: showData.type,
        date: showData.date,
        amount: showData.amount,
        fid: showData.financialId,
        memo: showData.memo,
        category: showData.category,
        total: showData.total,
      },
      
    });

    return (
      <Modal backdrop="static" show={showData.showModal} onHide={() => handleCancel(updateMade)} centered aria-labelledby="modal-transaction" >
        <Modal.Header closeButton>
          {showData.dbId > 0 && (
            <Modal.Title id="modal-transaction">Update Transaction</Modal.Title>
          )}
          {showData.dbId == 0 && (
            <Modal.Title id="modal-transaction">Add Transaction</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="transaction.financialid">
                  <Form.Label>Financial ID:</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="fid"
                    value={formik.values.fid}
                    onChange={formik.handleChange}
                  /> 
                </Form.Group>
              </Col>            
              <Col>
                <Form.Group className="mb-3" controlId="transaction.type">
                  <Form.Label>Type:</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}                  
                    isInvalid={!!formik.errors.type}         
                  /> 
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="transaction.date">
                  <Form.Label>Date:</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="date"
                    value={dateFormat(formik.values.date, "yyyy-mm-dd")} 
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.date} 
                  />            
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="transaction.amount">
                  <Form.Label>Amount:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                    <Form.Control 
                      className='financial-input'
                      type="number" 
                      name="amount"
                      value={formik.values.amount} 
                      onChange={formik.handleChange}
                      isInvalid={!!formik.errors.amount} 
                    />            
                  </InputGroup>
                  <Form.Text align="right">
                    Balance: {formatMoney(formik.values.total)}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.amount}
                  </Form.Control.Feedback>
                </Form.Group>         
              </Col>
            </Row>          
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="transactions.memo">
                  <Form.Label>Memo:</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="memo"
                    value={formik.values.memo} 
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.memo}  
                  />            
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.memo}
                  </Form.Control.Feedback>
                  <Form.Text id="memo23" muted>
                    {showData.memo2}
                    {showData.memo3}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <AutoComplete 
                  initialValue={transaction.note}
                  possibleValues={notes} 
                  onChange={(e) => setTransaction({...transaction, 
                                                  note: e})} 
                  onSelection={(id) => onSelectionMade(id)}
                />
              </Col>
            </Row>               
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="transactions.category">
                  <Form.Label>Category:</Form.Label>
                  <Form.Select 
                    aria-label="Transaction Category" 
                    id="dropdown-category" 
                    title="Category" 
                    ref={selectRef}
                    defaultValue={showData.category}
                    onChange={(e) => formik.values.category = e.target.value} >
  
                      {showCategories()}
                  </Form.Select>                      
                </Form.Group>            
              </Col>
            </Row>       
            <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="transaction.tranfercheck">
                    <Form.Check
                      type="switch"
                      id="transfer-switch"
                      label="Transfer Transaction"
                      checked={transfer}
                      onChange={(e) => setTransfer(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {bankAccounts != null && (
                    <Form.Group className="mb-3" controlId="transaction.selection">
                      {formik.values.amount < 0 && (
                        <Form.Label>Transfer To:</Form.Label>
                      )}
                      {formik.values.amount > 0 && (
                        <Form.Label>Transfer From:</Form.Label>
                      )}
                      <Form.Select 
                        aria-label="Select Account"
                        id="dropdown-bankaccount" 
                        title="Bank Account" 
                        defaultValue={showData.transferId}
                        onChange={(e) => setTransferBankAccount(e.target.value)} 
                        disabled={!transfer}
                      >
                        {bankAccounts.map((post) => {
                          if(post.id != showData.accountId) {
                            return (
                              <option key={post.id} value={post.id}>{post.name}{' - '}{post.acc}</option>
                            )
                          }
                        })}
                      </Form.Select>                      
                    </Form.Group> 
                  )}                    
                </Col>
            </Row>
            {saving == false && (
              <Modal.Footer>
                {showData.dbId == 0 && (
                  <Form.Check
                      type="switch"
                      id="add-more"
                      label="Add another"
                      checked={addMore}
                      onChange={(e) => setAddMore(e.target.checked)}
                  />
                )}
                <Button disabled={!formik.isValid} onClick={() => handleSubmit(formik.values)}>Record</Button>                                
                {' '}
                <Button variant="secondary" onClick={() => handleCancel(updateMade)}>Cancel</Button> 
              </Modal.Footer>
            )}
            {errorMessage != "" && (
              <Alert key='warning' variant='warning'>
                {errorMessage}
              </Alert>
            )}
            {saving == true && (
              <Modal.Footer>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Saving...</span>
                </Spinner>
              </Modal.Footer>
            )}
          </Form>
          
        </Modal.Body>
      </Modal> 
    )
  }

  export const UploadTransactionFile = ({showData, handleCancel}) => {
    const [fileImport, setFileImport] = useState();
    const [saving, setSaving] = useState(false);
  
    const loadTransactionFile = async () => {  
      setSaving(true);
  
      const formData = new FormData();
      formData.append("formFile", fileImport);
  
      var url = new URL("https://thepinkgiraffe.azurewebsites.net/Bank/Transactions");
  
      const bankTransactionRec = await fetch(url, {
        method: 'POST',
        headers: {      
          'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
          'Origin': 'https://localhost:44386'        
        },
        body: formData,
      })
      .then((response) => {
        if(response.status == 200) {        
          console.log(response.text());
          handleCancel(true);
        } else {
        }
  
        return response.text();
      })    
      .then((data) => {      
        console.log(data);
      })
      .catch((err) => {
      })
      .finally(() => {
        setSaving(false);
      })                
      
    }
  
    return (
      <Modal backdrop="static" show={showData} onHide={() => handleCancel(false)} centered aria-labelledby="modal-uploadtransactions" >
        <Modal.Header closeButton>
          <Modal.Title id="modal-uploadtransactions">Upload bank transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Select import file:</Form.Label>
              <Form.Control 
                type="file" 
                name="transactions"   
                accept=".ofx"               
                onChange={(e) => setFileImport(e.target.files[0])}                  
              />            
            </Form.Group>
          </Form>
          {saving == false && (
            <Modal.Footer>
              <Button variant="primary" disabled={fileImport == null} onClick={loadTransactionFile}>Import</Button>
              <Button variant="secondary" onClick={() => handleCancel(false)}>Cancel</Button>
            </Modal.Footer>
          )}
          {saving == true && (
            <Modal.Footer>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Saving...</span>
              </Spinner>
            </Modal.Footer>
          )}
        </Modal.Body>
      </Modal>
    )
  }