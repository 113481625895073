import { useState, useEffect, useMemo, useRef } from 'react';
import { Badge, Container, Row, Col, Spinner, Navbar, Nav, Form, NavDropdown, Modal, Button, Alert, InputGroup, Placeholder } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip ,Legend, AreaChart, Area, ResponsiveContainer} from 'recharts';
import { AlertDismiss } from './AlertDismiss.js';
import dateFormat from 'dateformat';
import Table from "./Table.js";
import { Moment, now } from 'moment/moment.js';

import {BankAccountUpdate} from './BankAccountUpdate.js'
import { TransactionUpdate, UploadTransactionFile } from './TransactionUpdate.js';
import { TransactionCategories } from './TransactionCategory.js';

export const CategoryCombo = ({cId, categories, transaction}) => {

  const findCategoryById = (id) => {
    var index = categories.find((cat) => cat.id == id);

    if(index != null) {
      return index;
    }
  }

  const findCategoryTypeById = (id) => {
    var index = findCategoryById(id);

    if(index != null) {
      return index.ct;
    } else {
      return 0;
    }
  } 

  useEffect(()=> {

  }, []);

  const GetBadge = () => {
    if(transaction.cat != "") {
      var badgeType = "primary";
      var id = findCategoryTypeById(cId);

      if(id == 1) {
        badgeType = "success";
      } else if(id == 2) {
        badgeType = "info";
      }

      return (
        <Badge bg={badgeType}>{transaction.cat}</Badge>
      )
    } else {
      return (
        <Badge bg="warning">Unassigned</Badge>
      )
    }
  }

  return (
    <>    
    {GetBadge()}    
    </> 
  )
}


export const BankingInformation = () => { 
  const [messageShow, setMessageShow] = useState(false);  
  const [saving, setSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [displayBankAccounts, setDisplayBankAccounts] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [overallTransactions, setOverallTransactions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [transactionNotes, setTransactionNotes] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [confirmCheck, setConfirmCheck] = useState([]);
  const [defaultPage, setDefaultPage] = useState(0);
  const [defaultPageTrigger, setDefaultPageTrigger] = useState(0);
  const [currentTransPage, setCurrentTransPage] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [pendingOnly, setPendingOnly] = useState(false);

  const[showBankAccount, setShowBankAccount] = useState({
    dbId: 0,
    showModal: false,
    name: "",
    sortCode: "",
    accountNo: "",
    openingBalance: 0.0,
    type: ""
  });

  const[showTransaction, setShowTransaction] = useState({
    id: 0,
    dbId: 0,
    accountId: 0,
    showModal: false,
    type: "PAYMENT",
    date: dateFormat(now(), "yyyy-mm-dd"),
    amount: 0.00,
    financialId: "",
    memo: "",
    memo2: "",
    memo3: "",
    category: "Household",
    total: 0.0,
    note: "",
    transferId: 0,
    confirmed: false
  });

  const[showModal, setShowModal] = useState({
    Id: 0,
    DbId: 0,
    Show: false,
    type: 1
  });
  const[showTransactionUpload, setShowTransactionUpload] = useState(false);

  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
     
    return tempToken;
  }

  const getRequest = async (e, path, param, paramValue) => {

    var url = new URL("https://thepinkgiraffe.azurewebsites.net/" + path);
    if(param != null) {
      url.searchParams.append(param, paramValue);
    }

    return fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      }})
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.messsage);
          return null;
        })
  }

  const sendRequest = async (e, path, param, paramValue, methodType = "DELETE") => {

    var url = new URL("https://thepinkgiraffe.azurewebsites.net/" + path);
    if(param != null) {
      url.searchParams.append(param, paramValue);
    }

    return fetch(url, {
      method: methodType,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => {
        if(response.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
        return false;
      });
  }

  const sendRequestWithBody = async (e, path, data, methodType = "DELETE") => {

    var url = new URL("https://thepinkgiraffe.azurewebsites.net/" + path);

    return fetch(url, {
      method: methodType,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if(response.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
        return false;
      });
  }


  const deleteBankAccount = async (id) => {
    return sendRequest(readToken(), "Bank/Delete", "accountid", id, "DELETE");
  }

  const deleteTransaction = async (id) => {
    return sendRequest(readToken(), "Bank/DeleteTrans", "transactionId", id, "DELETE");
  }

  const confirmTransaction = async (ids) => {
    return sendRequestWithBody(readToken(), "Bank/Confirm", ids, "POST");
  }

  const getTransactions = async(id) => {
    var token = readToken();

    const [transactionResponse] = 
      await Promise.all([
        getRequest(token, "Bank/GetTransactions", "accountId", id),
      ])

    const transOutput = await transactionResponse.json();
    setOverallTransactions(transOutput);
    setVisibileTransactions(transOutput, pendingOnly);
  }

  const getBankAccounts = async() => {
    var token = readToken();

    const [bankAccountResponse] = 
      await Promise.all([
        getRequest(token, "Bank/GetAccount", null, null),
      ])

    const bankAccounts = await bankAccountResponse.json();
    setBankAccounts(bankAccounts);

    return bankAccounts;
  }

  const readAllMethods = async() => {
    var token = readToken();

    const [categoryResponse] = 
      await Promise.all([
        getRequest(token, "Bank/GetCategories", null, null), 
      ])
    
    const categories =  await categoryResponse.json();
    setCategories(categories);

    const [notesResponse] = 
      await Promise.all([
        getRequest(token, "Bank/GetNotes", null, null),
      ])

    const notes = await notesResponse.json();
    setTransactionNotes(notes);
  
    const accounts = getBankAccounts();
    
    if(accounts != null && accounts.length > 0) {
      setSelectedAccount(accounts[0].id);
      getTransactions(accounts[0].id);
    }
  }

  const setVisibileTransactions = (data, pending) => {
    if(pending == false) {
      setTransactions(data.slice(0));
    } else {
      setTransactions(data.slice(0).filter(x => x.conf == 0));
    }
  }

  //only run on first render
  useEffect(()=> {
    readAllMethods();
    
  }, []);

  const formatPercentage = (value) => {
    // If using moment.js
    return value.toFixed(2).toString() + "%";
  }

  const getBody = () => {

    if(showModal.type == 1) {
      if(bankAccounts != null && bankAccounts.length > 0  && showModal.Id >= 0 && bankAccounts.length > showModal.Id) {
        return (
          <Modal.Body>
            <p>Are you sure you want to delete the bank account          
            {' '}
            {bankAccounts[showModal.Id].name}          
            {'?'}
            </p>
          </Modal.Body>
        )
      }
    } else {
      if(transactions != null && transactions.length > 0  && showModal.Id >= 0 && transactions.length > showModal.Id) {
        return (
          <Modal.Body>
            <p>Are you sure you want to delete the transaction         
            {' '}
            {transactions[showModal.Id].memo}          
            {', on date '}
            {dateFormat(transactions[showModal.Id].date, 'yyyy/mm/dd')}    
            {'?'}
            </p>
          </Modal.Body>
        )
      }     
    }
  }

  const deleteAccount = async () => {

    setDeleting(true);

    try
    {
      var deleted = false;

      if(showModal.type == 1) {
        deleted = await deleteBankAccount(showModal.DbId);
      } else {
        deleted = await deleteTransaction(showModal.DbId);
      }

      if(deleted == true) {
        //hide the confirmation box
        hideModal();

        if(showModal.type == 1) {
          //remove from local data list
          setBankAccounts(bankAccounts.filter(item => item.id != showModal.DbId));
        } else {
          //how do we the current page index???
          setDefaultPage(currentTransPage);
          //remove from local data list
          setOverallTransactions(overallTransactions.filter(item => item.id != showModal.DbId ));
          setVisibileTransactions(overallTransactions.filter(item => item.id != showModal.DbId ), pendingOnly);

          setDefaultPageTrigger((currentTrigger) => currentTrigger + 1);
        }      
      } else {
        //hide the confirmation box
        hideModal();
        //show the error to the audience
        setAlertMessage("Error while deleting");
        setMessageShow(true);
      }
    } catch(error) {
      setAlertMessage(error.message);
    } finally {
      setDeleting(false);
    }
  }

  const transPageUpdate = (page) => {
    setCurrentTransPage(page);
  }

  const confirmDelete = (id, type = 1) => {

    setShowModal({
      Id: id,
      DbId: (type == 1)? bankAccounts[id].id: transactions[id].id,
      Show: true,
      type: type
    });
  }

  const hideModal = (reset = false) => {
    setShowModal({...showModal, Show: false});
  } 

  const showBankAccountEdit = (id) => {
    if(id != -1 && bankAccounts != null && bankAccounts.length > id) {
      editBankAccount(bankAccounts[id]);
    }
  }      

  const showTransactionEdit = (id) => {
     if(id != -1 && transactions != null && transactions.length > id) {
       editTransaction(transactions[id], id);
     }
  }   

  const hideTransactionUpload = (reset = false) => {
    setShowTransactionUpload(false);

    if(reset) {
      setDefaultPage(0);
      setOverallTransactions([]);
      setTransactions([]);
      getTransactions(selectedAccount);
    }
  }

  const editBankAccount = (post) => {
    if(post == null) {
      setShowBankAccount({...showBankAccount,
        showModal: true,
        dbId: 0,
        name: "",
        sortCode: "",
        accountNo: "",
        openingBalance: 0,
        type: "Current"        
      });
    } else {
      setShowBankAccount({...showBankAccount,
        showModal: true,
        dbId: post.id,
        name: post.name,
        sortCode: post.sc,
        accountNo: post.acc,
        openingBalance: post.obal,
        type: post.type
      });  
    }
  }

  const editTransaction = (post, id) => {
    if(post == null) {
      setShowTransaction({...showTransaction,
        showModal: true,
        id: -1,
        dbId: 0,
        accountId: selectedAccount,
        type: "PAYMENT",
        date: dateFormat(now(), "yyyy-mm-dd"),
        amount: 0.00,
        financialId: "",
        memo: "",
        memo2: "",
        memo3: "",
        category: 43,
        total: 0,
        note: "",
        transferId: 0,
        confirmed: false
      });
    } else {
      setShowTransaction({...showTransaction,
        id: id,
        showModal: true,
        dbId: post.id,
        accountId: selectedAccount,
        type: post.type,
        date: post.date,
        amount: post.amount,
        financialId: post.fid,
        memo: post.memo,
        memo2: post.memo2,
        memo3: post.memo3,
        category: post.cid,
        total: post.tot, 
        note: post.nts,
        transferId: post.tid,
        confirmed: post.conf
      });  

    }
  }

  const handleTransactionCancel = (reset = false) => {
    setShowTransaction({...showTransaction,
      showModal: false
    });

    if(reset === true) {
      getTransactions();
    }
  }

  const handleCancel = (reset = false) => {
    setShowBankAccount({...showBankAccount,
      showModal: false
    });

    if(reset === true) {
      getBankAccounts();
    }   
  }

  const formatLabel = (value) => {
    // If using moment.js
    return "£" + value.toFixed(2).toString();
  }
  
  const formatDate = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm/dd");
  }


  const selectNewAccount = (id) => {
    setDefaultPage(0);
    setOverallTransactions([]);
    setTransactions([]);
    setSelectedAccount(id);
    getTransactions(id);
  }

  const bankAccountsTable = useMemo(
    () => [
      {
        Header: "Bank Accounts",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Sort Code",
            accessor: "sc",
            className: "paymentTypeColumn",
            Cell: ({cell : { value }}) => <>{value}</>             
          },
          {
            Header: "Account No",
            accessor: "acc",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Opening Balance",
            accessor: "obal",
            className: "paymentTypeColumn",
            Cell: ({cell : { value }}) => <>{formatLabel(value)}</> 
          },
          {
            Header: "Type",
            accessor: "type",
            className: "paymentTypeColumn",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Account Balance",
            accessor: "abal",
            Cell: ({cell : { value }}) => <>{formatLabel(value)}</> 
          },
          {
            Header: "Actions",
            Cell: ({row}) => <>
              <img src="../image/edit2.png" alt="edit" onClick={() => showBankAccountEdit(row.index)} width={32} height={32}/>
              <img src="../image/delete.png" alt="delete" onClick={() => confirmDelete(row.index, 1)} width={32} height={32}/>
              </>
          }
        ]
      }
    ]
  )

  const setTransactionFull = (id, updated) => {
    transactions[id].cid = updated.cid;
    transactions[id].cat = updated.cat;
    transactions[id].nts = updated.nts;
    transactions[id].memo = updated.memo;
    transactions[id].type = updated.type;
    transactions[id].date = updated.date;
    transactions[id].fid = updated.fid;
    transactions[id].amount = updated.amount;
    transactions[id].tid = updated.tid;
    transactions[id].conf = true;
  }

  const isChecked = (id) => {
    return confirmCheck.some(i => i === id);
  }

  const anyChecked = () => {
    return (confirmCheck.length == 0)? false: true;
  }

  const toggleChecked = (id) => {
    //make a copy
    var newArray = confirmCheck.slice();

    if(!newArray.some(i => i === id)) {
      //add the extra one
      newArray.push(id);
    } else {
      newArray = [];
      //add all except the toggled one
      confirmCheck.map((i) => {
        if(i != id) {
          newArray.push(i);
        }
      });
    }

    setConfirmCheck(newArray);
  }

  const performConfirmation = async() => {
    setSaving(true);

    const [confirmResponse] = 
    await Promise.all([
      confirmTransaction(confirmCheck)
    ]);
    
    if(confirmResponse == true) {
      for(var i = 0; i < confirmCheck.length; i++) {
        var transIndex = transactions.findIndex((item) => (item.id == confirmCheck[i])? true: false);

        if(transIndex >= 0) {
          transactions[transIndex].conf = true;
        }  
      }
    } else {
      setAlertMessage("Could not complete confirmation of transactions");
      setMessageShow(true);
    }

    //after confirmation, clear it
    setConfirmCheck([]);
    setSaving(false);
  }

  const performConfirmCheck = (value, row) => {
    if(value == 0 && transactions[row.index].cat) {   
      var id = transactions[row.index].id;

      return  (
        <Form.Check 
          checked={isChecked(id)}
          onChange={() => toggleChecked(id)} />

      )
    }
  }

  const transactionTable = useMemo(
    () => [
      {
        Header: "Transactions",
        columns: [
          {
            Header: "Type",
            accessor: "type",
            className: "paymentTypeColumn",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Date",
            accessor: "date",
            Cell: ({cell : { value }}) => <>{formatDate(value)}</>             
          },
          {
            Header: "Notes",
            accessor: "nts",
            Cell: ({cell : { value }, row}) => <>{(value == "")? transactions[row.index].memo: value}</> 
          },
          {
            Header: "Category",
            accessor: "cat",
            Cell: ({row}) => <CategoryCombo cId={transactions[row.index].cid} 
                                            categories={categories} 
                                            transaction={transactions[row.index]}
                                            /> 
          },
          {
            Header: "Conf",
            accessor: "conf",
            Cell: ({cell: { value }, row}) => <>{performConfirmCheck(value, row)}</>
          },
          {
            Header: "Trans",
            accessor: "tid",
            className: "totalTransactionColumn",
            Cell: ({cell: { value }, row}) => <>{(value != 0)? "T": ""}</>
          },
          {
            Header: "Amount",
            accessor: "ta",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Total",
            accessor: "ts",
            className: "totalTransactionColumn",
            Cell: ({cell : { value }}) => <>{value}</> 
          },          
          {
             Header: "Actions",
             Cell: ({row}) => <>
               <img src="../image/edit2.png" alt="edit" onClick={() => showTransactionEdit(row.index)} width={32} height={32}/>
               <img src="../image/delete.png" alt="delete" onClick={() => confirmDelete(row.index, 2)} width={32} height={32}/>
               </>
          }
        ]
      }
    ]
  )
  
  return (
    <>
      <div>
        <AlertDismiss heading="Information!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>
      <div>
        <Navbar collapseOnSelect sticky="top" expand="sm" bg="dark" data-bs-theme="dark">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-banking" />
            <Navbar.Brand href="/bankinginformation">Banking</Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-banking">
              <Nav className="me-auto">
                {displayBankAccounts && (
                  <Nav.Link><div onClick={() => setDisplayBankAccounts(false)}>Hide Bank Accounts</div></Nav.Link>                  
                )}
                {!displayBankAccounts && (
                  <Nav.Link><div onClick={() => setDisplayBankAccounts(true)}>View Bank Accounts</div></Nav.Link>                  
                )}
                <Nav.Link><div onClick={() => editBankAccount()}>Add Bank Account</div></Nav.Link>                  
                <Nav.Link><div onClick={() => setShowTransactionUpload(true)}>Upload Transactions</div></Nav.Link>
              </Nav>        
            </Navbar.Collapse>           
          </Container>        
        </Navbar>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Wealth</Badge>
      </h1>

      {showTransactionUpload == true && (
        <UploadTransactionFile showData={showTransactionUpload} handleCancel={hideTransactionUpload}/>
      )}
      
      <Modal show={showModal.Show} onHide={hideModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        {getBody()}

        <Modal.Footer>
          {!deleting && (
            <>
            <Button variant="primary" onClick={deleteAccount}>Yes</Button>
            <Button variant="secondary" onClick={hideModal}>No</Button>
            </>
          )}
          {deleting && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Deleting...</span>
            </Spinner>
          )}

        </Modal.Footer>
      </Modal>

      {showBankAccount != null && (
          <BankAccountUpdate 
            showData={showBankAccount} 
            handleCancel={handleCancel}
          />
      )}

      {categories != null && (
        <TransactionUpdate 
          showData={showTransaction} 
          handleCancel={handleTransactionCancel} 
          categories={categories} 
          bankAccounts={bankAccounts}
          baseTransaction={transactions}
          setBaseTransaction={setTransactionFull}
          notes={transactionNotes}
        />
      )}


      <Container fluid="md">
        <Row>
          <Col>
            {/* {categories != null && (
              <TransactionCategories importedCategories={categories} />
            )}  */}
          </Col>
        </Row>
        {displayBankAccounts && (
          <Row className="justify-content-md-center">
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              {bankAccounts != null && bankAccounts.length > 0 && (
                <>
                  <Button variant="primary" onClick={() => editBankAccount(null)}>Add New Account</Button>
                  <Table columns={bankAccountsTable} data={bankAccounts}  />
                </>
              )}
              {(bankAccounts == null || bankAccounts.length == 0) && (
                <center>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </center>
              )}
            </Col>

          </Row>
        )}
        <Row>
         
          <Col sm={12} md={6} lg={4} xl={4} xxl={4}>
            <Form>
                <Form.Group className="mb-3" controlId="account.selection">
                  <Form.Label>Bank Account selected:</Form.Label>
                  {bankAccounts != null && bankAccounts.length > 0 && (
                    <>
                    <Form.Select 
                      aria-label="Select Account"
                      id="dropdown-bankaccount" 
                      title="Bank Account" 
                      onChange={(e) => selectNewAccount(e.target.value)} 
                    >
                      {bankAccounts.map((post) => {
                        return (
                          <option key={post.id} value={post.id}>{post.name}{' - '}{post.acc}</option>
                        )
                      })}
                    </Form.Select>   
                    <Button onClick={() => selectNewAccount(selectedAccount)}>Refresh</Button> 
                    </>
                  )}                   
                  {bankAccounts == null || bankAccounts.length == 0 && (
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  )}
              </Form.Group> 
            </Form>
          </Col>

        </Row>
        <Row className="justify-content-md-center">
          <Col sm={12} md={12}>
            {bankAccounts != null && bankAccounts.length > 0 && (
              <Button variant="primary" onClick={() => editTransaction(null)}>Add New Transaction</Button>
            )}
            {' '}
            {anyChecked() && (
              <>
                {saving == false && (
                  <Button variant="primary" onClick={() => performConfirmation()}>Save</Button>
                )}
                {saving == true && (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Savings...</span>
                  </Spinner>
                )}
              </>
            )}
            {!anyChecked() && (
              <Button variant="primary" disabled>Save</Button>
            )}

            <Form.Check type="switch"
                      id="pending-switch"
                      label="Pending Only"
                      checked={pendingOnly}
                      onChange={(e) => {
                        setPendingOnly(e.target.checked);
                        setVisibileTransactions(overallTransactions, e.target.checked);
                      } } 
            />
            {(transactions != null && transactions.length > 0) && (
              <Table columns={transactionTable} 
                data={transactions} 
                defaultPage={defaultPage} 
                pageNotifier={transPageUpdate} 
                pageTrigger={defaultPageTrigger}/>
            )}     
            {(transactions != null && transactions.length == 0) && (
              <p>No records to show</p>
            )}
            {(transactions == null) && (
              <center>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </center>
            )}
            <table>
              <tbody>
              </tbody>
            </table>
          </Col>
          <Col sm={6} md={2}>
            <table>
              <tbody>          
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={6} xl={6} xxl={6} >
          </Col>
          <Col sm={12} md={12} lg={6} xl={6} xxl={6} >
          </Col>
        </Row>
      </Container>
    </>
  );
  
}
